<template>
    <div class="historyDetails-wrapper junglemines-myHistoryWrap">
        <div class="historyDetails-topHeadBox">
            <div class="backToApp" id="close">
                <div class="angle"></div>
            </div>
            <div class="historyDetails-topHD">
                <h2>My History</h2>
            </div>
        </div>
        <div class="historyTable-wrapper junglemines-myHistoryTable">
            <LoadingOverlay :loading="loading" class="popupLoading" />
            <div class="table-responsive" ref="tableContainer">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th style="width: 96.17px;">My Bet</th>
                            <th style="width: 85px;">Prizes</th>
                            <th style="width: 96px;">Multipler</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="jungleMinesGameMyHistoryData.length === 0">
                            <td colspan="4" class="text-center">No data available</td>
                        </tr>
                        <tr v-else v-for="bet in jungleMinesGameMyHistoryData" :key="bet.contestid">
                            <td>{{ bet.contestid_hm }}</td>
                            <td>
                                <div>{{ bet.bet_amount }} 
                                    <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                </div>
                            </td>
                            <td>{{ bet.win_amount }}
                                <span class="diamond-iconBox"><img :src='diamondIcon' alt="" />
                                </span>
                            </td>
                            <td>{{ bet.multiplier.toFixed(2) }}X</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import LoadingOverlay from '../LoadingOverlay.vue'
import diamondIcon from '../../assets/images/diamond.png'
export default {
    name: 'JungleMinesMyHistory',
    components: {
        LoadingOverlay,
    },
    data() {
        return {
            jungleMinesGameMyHistoryData: [],
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1,
            diamondIcon: diamondIcon
        }
    },
    mounted() {
        // Get user_id from route params
        this.userId = this.$route.params.user_id;

        if (this.userId) {
            this.jungleMinesGameMyHistory(); // Fetch history
            this.setupScrollListener(); // Setup infinite scrolling
        } else {
            console.error('User ID is missing in the route');
        }
    },
    methods: {
        jungleMinesGameMyHistory() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/mines-game-history?user_id=${this.userId}&page=${this.currentPage}`;
            axios.get(apiUrl)
                .then(response => {
                    const { result, total_pages } = response.data;

                    this.jungleMinesGameMyHistoryData = [
                        ...this.jungleMinesGameMyHistoryData,
                        ...result,
                    ];
                    this.totalPages = total_pages;
                })
                .catch(error => {
                    console.error('Error fetching history data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.jungleMinesGameMyHistory();
            }
        },
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        }
    },
}    
</script>