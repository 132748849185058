import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import 'bootstrap/dist/css/bootstrap.css' // Import Bootstrap CSS
import 'bootstrap' // Import Bootstrap JavaScript
import axios from 'axios';

axios.defaults.baseURL = 'https://zeep.live/'

createApp(App).use(router).mount('#app');
