<template>
    <div class="luckyRewards-wrapper spaceCraftWrapper">
        <div class="backgroundWrapper">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">Speed Rank</h2>
                    </div>
                </div>
                <div class="speedRank-topSec">
                    <h3 class="txtb" ref="heading">{{ this.SpaceCraftGameRankData.selectedTrackName }}</h3>
                    <img class="date-bg" :src="tracksImageData" alt="Date Bg" :style="{ width: imageWidth + 'px' }"  />
                    <img :src="getSpaceTrackImageSrc(this.SpaceCraftGameRankData.selectedTrackName)" class="spaceTrack-image"
                        :alt="this.SpaceCraftGameRankData.selectedTrackName" />
                </div>
            </div>
            <div class="speedRank-listWrapper">
                <ul>
                    <li v-for="(roadSpeed, carName, index) in trackSpeedData" :key="roadSpeed"
                        :class="{ 'active': isSelectedCar(carName) }">
                        <div class="speedRank-listInnerBox">
                            <div class="leftBxb">
                                <div class="s-no">{{ index + 1 }}</div>
                            </div>
                            <div class="middleBox1">
                                <div class="topGame-profPic">
                                    <img :src="getCarImageSrc(carName).imagePath" :alt="carName" class="car-image" />
                                </div>
                            </div>
                            <div class="middleBox2">
                                <span>{{ carName }}</span>
                            </div>
                            <div class="rightBxb">
                                <div>{{ roadSpeed }}
                                    <span class="speed-image-icon">
                                        <img :src="getSpeedMeterIcon(isSelectedCar(carName))" class="speedMeterIcon"
                                            :alt="isSelectedCar(carName) ? 'Active Speed Meter Icon' : 'Speed Meter Icon'" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import tracksImageData from '../../assets/images/spaceCrafts/spaceTrack/road_name_bg.png'
import speedMeterIcon from '../../assets/images/car-game/speed_meter_icon.png'
import activeSpeedMeterIcon from '../../assets/images/car-game/speed_meter_icon-active.png'
export default {
    name: 'CarGameSpeedRank',
    data() {
        return {
            SpaceCraftGameRankData: {
                selectedTrackName: '',
                selectedspacecrafts: []
            },
            trackSpeedData: {},
            tracksImageData: tracksImageData,
            speedMeterIcon: speedMeterIcon,
            activeSpeedMeterIcon: activeSpeedMeterIcon,
            defaultSpaceTrackImage: require('../../assets/images/spaceCrafts/spaceTrack/SpacePath.webp'),
            imageWidth: 0,
        }
    },
    mounted() {
        this.contestId = this.$route.params.contest_id;
        this.fetchSpaceCraftGameRankData();
        this.updateImageWidth();
    },
    watch: {
        // Watch for changes to the content of the selected track name
        "SpaceCraftGameRankData.selectedTrackName": function () {
        this.$nextTick(() => {
            this.updateImageWidth();
        });
        },
    },
    methods: {
        fetchSpaceCraftGameRankData() {
            const apiUrl = `api/spacecrafts-game/speed_rankapi.php?contest_id=${this.contestId}&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.SpaceCraftGameRankData = response.data.result;
                    this.trackSpeedData = response.data.result.trackSpeedData;
                    // console.warn(apiUrl)
                })
                .catch(error => {
                    console.error("Error Featching SpaceCraftGame Rank Data", error)
                })
        },

        updateImageWidth() {
            const heading = this.$refs.heading;
            if (heading) {
                const headingWidth = heading.offsetWidth; // Get the content width of the <h3>
                this.imageWidth = headingWidth +30; // Add 20px padding
            }
        },
        getSpaceTrackImageSrc(SpaceTrackImage) {
            if (!SpaceTrackImage) return '';
            const sanitizedSpaceTrackImageName = SpaceTrackImage.replace(/\s+/g, '');
            try {
                return require(`../../assets/images/spaceCrafts/spaceTrack/${sanitizedSpaceTrackImageName}.webp`);
            } catch (e) {
                console.error(`Image not found for ${sanitizedSpaceTrackImageName}, using default image.`);
                return this.defaultSpaceTrackImage;
            }
        },
        getCarImageSrc(carName) {
            const sanitizedImageName = carName.replace(/\s+/g, '');
            try {
                const imagePath = require(`../../assets/images/spaceCrafts/${sanitizedImageName}.png`);
                const speed = this.trackSpeedData[carName] || 'N/A';
                return { imagePath, speed };
            } catch (e) {
                console.error(`Image not found for ${sanitizedImageName}, using default image.`);
                return { imagePath: '', speed: 'N/A' };
            }
        },
        isSelectedCar(carName) {
            return this.SpaceCraftGameRankData.selectedspacecrafts.includes(carName);
        },
        getSpeedMeterIcon(isActive) {
            return isActive ? this.activeSpeedMeterIcon : this.speedMeterIcon;
        },

        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        },
    }
}
</script>