<template>
    <div class="carSpeedRankContainer-- luckyRewards-wrapper spaceCraftWrapper">
        <div class="backgroundWrapper"
            v-if="SpaceCraftGamedata && SpaceCraftGamedata.graph_spacecrafts_image && SpaceCraftGamedata.graph_image">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">{{ currentCar }}</h2>
                    </div>
                </div>
            </div>
            <div class="speedRank-listWrapper">
                <div class="podiumCarWrapper">
                    <div class="carImage">
                        <img :src="spaceCraftsImageUrl" :alt="currentCar" />
                    </div>
                    <div class="VictoryOddsBoxWrap">
                        <div class="VictoryBox">Victory : {{ carWinPercentage }}</div>
                        <div class="OddsBox">Odds : {{ carOddsVal }}</div>
                    </div>
                    <button type="button" class="customButton previousButton" :disabled="isFirstCar"
                        :class="{ disabled: isFirstCar }" @click="previousCar">
                        <span class="arrowButton"></span>
                    </button>
                    <button type="button" class="customButton nextButton" :disabled="isLastCar"
                        :class="{ disabled: isLastCar }" @click="nextCar">
                        <span class="arrowButton"></span>
                    </button>
                </div>
                <div class="graphImage">
                    <!-- <p>{{ spaceCraftsImageUrl }}</p> -->
                    <img :src="graphImageUrl" :alt="'Graph ' + currentCar" />
                </div>
            </div>
        </div>
        <div v-else>
            <LoadingOverlay :loading="loading" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingOverlay from "../LoadingOverlay.vue";

export default {
    name: "SpaceCraftGameRoadGraph",
    components: {
        LoadingOverlay,
    },
    data() {
        return {
            SpaceCraftGamedata: null,
            spacecrafts: [
                "Nebula Phantom",
                "Asteroid Crusher",
                "Photon Speeder",
                "Alien Reaver",
                "Quantum Voyager",
                "Space X",
                "NASA Graviton",
                "Junkyard Runner",
                "Star Striker",
            ],
            currentIndex: 0,
            loading: false,
        };
    },
    computed: {
        currentCar() {
            return this.spacecrafts[this.currentIndex];
        },
        spaceCraftsImageUrl() {
            if (this.SpaceCraftGamedata && this.SpaceCraftGamedata.graph_spacecrafts_image) {
                return require(`../../assets/images/spaceCrafts/graphImages/${this.SpaceCraftGamedata.graph_spacecrafts_image[this.currentCar]}`);
            }
            return "";
        },
        carOddsVal() {
            if (this.SpaceCraftGamedata && this.SpaceCraftGamedata.standard_odds) {
                return this.SpaceCraftGamedata.standard_odds[this.currentCar] || "Odds not available";
            }
            return "Odds not available";
        },
        carWinPercentage() {
            if (this.SpaceCraftGamedata && this.SpaceCraftGamedata.spacecraft_win_per) {
                const winValue = this.SpaceCraftGamedata.spacecraft_win_per[this.currentCar];
                if (winValue !== undefined) {
                    const winPercentage = parseFloat(winValue);
                    if (!isNaN(winPercentage)) {
                        return `${winPercentage.toFixed(2)}%`;
                    }
                }
                return "Win percentage data not available";
            }
            return "Win percentage data not available";
        },

        graphImageUrl() {
            if (this.SpaceCraftGamedata && this.SpaceCraftGamedata.graph_image) {
                return require(`../../assets/images/spaceCrafts/graphImages/${this.SpaceCraftGamedata.graph_image[this.currentCar]}`);
            }
            return "";
        },
        isFirstCar() {
            return this.currentIndex === 0;
        },
        isLastCar() {
            return this.currentIndex === this.spacecrafts.length - 1;
        },
    },
    mounted() {
        this.fetchSpaceCraftGamedata();
        this.setCarFromUrl();
    },
    methods: {
        fetchSpaceCraftGamedata() {
            this.loading = true;
            const apiUrl = `api/spacecrafts-game/data_api.php`;
            axios
                .get(apiUrl)
                .then((response) => {
                    this.SpaceCraftGamedata = response.data;
                })
                .catch((error) => {
                    console.error("Error Fetching SpaceCraft graph data", error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setCarFromUrl() {
            const carName = this.$route.query.spacecraft;
            const index = this.spacecrafts.indexOf(carName);
            if (index !== -1) {
                this.currentIndex = index;
            } else {
                this.currentIndex = 0; // Default to the first spacecraft
            }
        },
        previousCar() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.updateUrl();
            }
        },
        nextCar() {
            if (this.currentIndex < this.spacecrafts.length - 1) {
                this.currentIndex++;
                this.updateUrl();
            }
        },
        updateUrl() {
            this.$router.replace({ query: { spacecraft: this.currentCar } });
        },
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        },
    },
};
</script>

<style scoped>
/* Add your styles here */
</style>
