<template>
    <div class="historyTable-wrapper flipCoin-historyTable">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="table-responsive" ref="tableContainer">
            <table class="table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th style="width: 96.17px;">Result</th>
                        <th style="width: 85px;">Winners</th>
                        <th style="width: 96.17px;">Prizes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="flipCoinGameBetHistory.length === 0">
                        <td colspan="4" class="text-center">No data available</td>
                    </tr>
                    <tr v-else v-for="bet in flipCoinGameBetHistory" :key="bet.contestid">
                        <td>{{ bet.contestid_hm }}</td>
                        <td>
                            <div v-if="bet.winning == 'choice1'">Under 7</div>
                            <div v-else-if="bet.winning == 'choice2'">Jackpot 7</div>
                            <div v-else>Over 7</div>                            
                        </td>
                        <td>{{ bet.total_winner }}</td>
                        <td>
                            <router-link :to="generateHistoryDetailsLink(bet.contestid)" class="go-histortDetails">
                                {{ bet.total_won }} <span class="angle"></span>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { RouterLink } from 'vue-router';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'LuckySevenHistory',
    components: {
        LoadingOverlay,
        RouterLink
    },
    data() {
        return {
            flipCoinGameBetHistory: [],
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1
        }
    },
    mounted() {
        this.userId = this.$route.params.user_id;
        this.flipCoinFetchBetHistory();
        this.setupScrollListener();
    },
    methods: {
        flipCoinFetchBetHistory() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/dies-game/bethistoryapi.php?page=${this.currentPage}`;
            axios.get(apiUrl)
                .then(response => {
                    this.flipCoinGameBetHistory = [...this.flipCoinGameBetHistory, ...response.data.data];
                    this.totalPages = response.data.total_pages;
                })
                .catch(error => {
                    console.error('Error fetching history data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        generateHistoryDetailsLink(contestId) {
            const userId = this.userId;
            return {
                path: '/lucky_seven/history-details/' +
                    `contest_id=${contestId}/user_id=${userId}`
            };
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.flipCoinFetchBetHistory();
            }
        }
    },
}
</script>