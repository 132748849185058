// import ParentabsComponent from './components/ParentTabsComponent/ParentTabsComponent.vue'
import ParentTabComponent from "@/components/ParentTabsComponent/ParentTabsComponent.vue";

import HistoryTabComponent from "./components/LuckyNumber/HistoryTabComponent.vue";
import HistoryDetails from "./components/LuckyNumber/HistoryDetails.vue";
import Rules from "./components/LuckyNumber/Rules.vue";

import LuckyRewards from "./components/LuckyNumber/LuckyRewards.vue";
import LuckyStatistics from "./components/LuckyNumber/LuckyStatistics.vue";

import CarGameHistoryTabComponent from "./components/CarGame/HistoryTabComponent.vue";
import CarGameHistoryDetails from "./components/CarGame/CarGameHistoryDetails.vue";
import CarGameRankTabComponent from "./components/CarGame/CarGameRankTabComponent.vue";
import CarGameWinRateRank from "./components/CarGame/CarGameWinRateRank.vue";
import CarGameSpeedRank from "./components/CarGame/CarGameSpeedRank.vue";
import CarGameRoadGraph from "./components/CarGame/CarGameRoadGraph.vue";
import CarGameRules from "./components/CarGame/CarGameRules.vue";

import FlipCoinHistoryTabComponent from "./components/FlipCoin/HistoryTabComponent.vue";
import FlipCoinHistoryDetails from "./components/FlipCoin/FlipCoinHistoryDetails.vue";
import FlipCoinRules from "./components/FlipCoin/FlipCoinRules.vue";

import IconicHost from "./components/IconicHost/IconicHost.vue";
import GroupBattle from "./components/GroupBattle/GroupBattle.vue";

import LuckySevenHistoryTab from "./components/LuckySeven/LuckySevenHistoryTabComponent.vue";
import LuckySevenHistoryDetails from "./components/LuckySeven/LuckySevenHistoryDetails.vue";
import LuckySevenRules from "./components/LuckySeven/LuckySevenRules.vue";

import ThimbleGameHistoryTab from "./components/ThimbleGame/ThumbleGameHistoryTabComponent.vue";
import ThimbleGameHistoryDetails from "./components/ThimbleGame/ThimbleGameHistoryDetails.vue";
import ThimbleGameRules from "./components/ThimbleGame/ThimbleGameRules.vue";

import JungleMinesMyHistory from "./components/JungleMines/JungleMinesMyHistory.vue";
import JungleMinesRules from "./components/JungleMines/JungleMinesRules.vue";

// New SpaceCraftGame
import SpaceCraftGameHistoryTabComponent from "./components/SpaceCraftGame/HistoryTabComponent.vue";
import SpaceCraftGameHistoryDetails from "./components/SpaceCraftGame/SpaceCraftGameHistoryDetails.vue";
import SpaceCraftGameRankTabComponent from "./components/SpaceCraftGame/SpaceCraftGameRankTabComponent.vue";
import SpaceCraftGameWinRank from "./components/SpaceCraftGame/SpaceCraftGameWinRank.vue";
import SpaceCraftGameSpeedRank from "./components/SpaceCraftGame/SpaceCraftGameSpeedRank.vue";
import SpaceCraftGameRoadGraph from "./components/SpaceCraftGame/SpaceCraftGameRoadGraph.vue";
import SpaceCraftGameRules from "./components/SpaceCraftGame/SpaceCraftGameRules.vue";

import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/topGame2",
    name: "topGame2",
    component: ParentTabComponent,
  },
  {
    name: "ParentabsComponent",
    component: ParentTabComponent,
    path: "/",
  },
  // Lucky Number Game URl
  {
    name: "HistoryTabComponent",
    component: HistoryTabComponent,
    path: "/luckynumber/history-tab/user_id=:user_id",
  },
  {
    name: "HistoryDetails",
    component: HistoryDetails,
    path: "/luckynumber/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "LuckyStatistics",
    component: LuckyStatistics,
    path: "/luckynumber/statistics/user_id=:user_id",
  },
  {
    name: "Rules",
    component: Rules,
    path: "/luckynumber/rules",
  },
  {
    name: "LuckyRewards",
    component: LuckyRewards,
    path: "/luckynumber/lucky-rewards/profile_id=:profile_id",
  },

  // Car Game URl
  {
    name: "CarGameHistoryTabComponent",
    component: CarGameHistoryTabComponent,
    path: "/cargame/history-tab/user_id=:user_id",
  },
  {
    name: "CarGameHistoryDetails",
    component: CarGameHistoryDetails,
    path: "/cargame/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "CarGameRankTabComponent",
    component: CarGameRankTabComponent,
    path: "/cargame/rank",
  },
  {
    name: "CarGameWinRateRank",
    component: CarGameWinRateRank,
    path: "/cargame/winrate_rank",
  },
  {
    name: "CarGameSpeedRank",
    component: CarGameSpeedRank,
    path: "/cargame/speed_rank/contest_id=:contest_id",
  },
  {
    name: "CarGameRoadGraph",
    component: CarGameRoadGraph,
    path: "/cargame/car_road_graph",
  },
  {
    name: "CarGameRules",
    component: CarGameRules,
    path: "/cargame/rules",
  },
  // FlipCoin URl
  {
    name: "FlipCoinHistoryTabComponent",
    component: FlipCoinHistoryTabComponent,
    path: "/flipcoin/history-tab/user_id=:user_id",
  },
  {
    name: "FlipCoinHistoryDetails",
    component: FlipCoinHistoryDetails,
    path: "/flipcoin/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "FlipCoinRules",
    component: FlipCoinRules,
    path: "/flipcoin/rules",
  },

  // IconicHost
  {
    name: "IconicHost",
    component: IconicHost,
    path: "/iconichost/iconic-host",
  },

  // Group Battle
  {
    name: "GroupBattle",
    component: GroupBattle,
    path: "/groupbattle/group-battle",
  },

  // Lucky Seven URl
  {
    name: "LuckySevenHistoryTab",
    component: LuckySevenHistoryTab,
    path: "/lucky_seven/history-tab/user_id=:user_id",
  },
  {
    name: "LuckySevenHistoryDetails",
    component: LuckySevenHistoryDetails,
    path: "/lucky_seven/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "LuckySevenRules",
    component: LuckySevenRules,
    path: "/lucky_seven/rules",
  },

  // Thimble Game URl

  {
    name: "ThimbleGameHistoryTab",
    component: ThimbleGameHistoryTab,
    path: "/thimble_game/history-tab/user_id=:user_id",
  },
  {
    name: "ThimbleGameHistoryDetails",
    component: ThimbleGameHistoryDetails,
    path: "/thimble_game/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "ThimbleGameRules",
    component: ThimbleGameRules,
    path: "/thimble_game/rules",
  },

  // JungleMines Games

  {
    name: "JungleMinesMyHistory",
    component: JungleMinesMyHistory,
    path: "/jungle_mines/myhistory/user_id=:user_id",
  },
  {
    name: "JungleMinesRules",
    component: JungleMinesRules,
    path: "/jungle_mines/rules",
  },

  // SpaceCraftGame
  {
    name: "SpaceCraftGameHistoryTabComponent",
    component: SpaceCraftGameHistoryTabComponent,
    path: "/spacecrafts-game/history-tab/user_id=:user_id",
  },
  {
    name: "SpaceCraftGameHistoryDetails",
    component: SpaceCraftGameHistoryDetails,
    path: "/spacecrafts-game/history-details/contest_id=:contest_id/user_id=:user_id",
  },
  {
    name: "SpaceCraftGameRankTabComponent",
    component: SpaceCraftGameRankTabComponent,
    path: "/spacecrafts-game/rank",
  },
  {
    name: "SpaceCraftGameWinRank",
    component: SpaceCraftGameWinRank,
    path: "/spacecrafts-game/win_rank",
  },
  {
    name: "SpaceCraftGameSpeedRank",
    component: SpaceCraftGameSpeedRank,
    path: "/spacecrafts-game/speed_rank/contest_id=:contest_id",
  },
  {
    name: "SpaceCraftGameRoadGraph",
    component: SpaceCraftGameRoadGraph,
    path: "/spacecrafts-game/space_graph",
  },
  {
    name: "SpaceCraftGameRules",
    component: SpaceCraftGameRules,
    path: "/spacecrafts-game/rules",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;
