<template>
    <div class="historyDetails-wrapper carGameHistoryDetails">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="historyDetails-topHeadBox">
            <router-link to="/cargame/history-tab/user_id=:user_id" class="back-histort">
                <div class="angle"></div>
            </router-link>
            <div class="historyDetails-topHD">
                <h2>Details</h2>
            </div>
        </div>
        <div class="historyDetails-header" v-if="topHeaderDetails">
            <div class="historyDetails-headerLeftBox">
                <div class="">{{ this.contestId }}</div>
                <div class="">
                    <img :src="getCarImageSrc(topHeaderDetails.winning_car)" class="winning-carImage"
                        :alt="topHeaderDetails.winning_car" />
                    <span class="odds" id="oddsData">{{ dynamicOdd }}/{{ getStandardOdd }}</span>
                </div>
            </div>
            <div class="historyDetails-headerrightBox">
                <div class="">
                    {{ topHeaderDetails.total_winner }}
                    <img :src='userIcon' class="userIcon" alt="" />{{ topHeaderDetails.total_won }}
                    <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                </div>
                <div class="">
                    <td>Tip : {{ winningNo.total_tips }} <span class="diamond-iconBox"><img :src='diamondIcon'
                                alt="" /></span></td>
                </div>
            </div>
        </div>
        <div class="historyDetailsTable-wrapper">
            <div class="table-responsive" ref="tableContainer">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th style="width: 100px">Winners <br />
                                <span v-if="topHeaderDetails">[{{ topHeaderDetails.total_winner }}]</span>
                            </th>
                            <th>Total Bet</th>
                            <th>Bet On <br />ATV</th>
                            <th>Prizes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(betsDetails, index) in carGameHistoryBetsDetails">
                            <tr v-if="betsDetails.status == 2 || betsDetails.status == 3" :key="betsDetails.contestid">
                                <td>{{ index + 1 }}</td>
                                <td style="width: 100px">
                                    <div class="user-picName-box">
                                        <div class="userPic">
                                            <img :src='(betsDetails.image.length > 0) ? betsDetails.image : defaultProfile'
                                                class="user" alt="User Image" />
                                        </div>
                                        <div class="userName">{{ betsDetails.name }}</div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">{{ betsDetails.total_bet }}</span>
                                        <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">{{ betsDetails.winning_no_bet }}</span>
                                        <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span class="totalBet">{{ betsDetails.total_won }}</span>
                                        <span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import diamondIcon from '../../assets/images/diamond.png'
import defaultProfile from '../../assets/images/default_profile.jpeg';
import userIcon from '../../assets/images/user-icon.png'
import LoadingOverlay from '../LoadingOverlay.vue'
import { RouterLink } from 'vue-router'
export default {
    name: 'CarGameHistoryDetails',
    props: {
        contest_id: String,
        user_id: String,
    },
    components: {
        LoadingOverlay,
        RouterLink
    },
    data() {
        return {
            carGameHistoryBetsDetails: [],
            diamondIcon: diamondIcon,
            defaultProfile: defaultProfile,
            userIcon: userIcon,
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1,
            contestId: '',
            userId: '',
            winning_no: null,
            dynamic_odd: 0,
            standard_odd: 0,
        }
    },
    computed: {
        smallBig() {
            return this.winningNo.winning_no < 14 ? 'S' : 'B';
        },
        evenOdd() {
            return this.winningNo.winning_no % 2 === 0 ? 'E' : 'O';
        },
        dynamicOdd() {
            let dynamic_odd = (this.winningNo.total_bet > 0 && this.winningNo.total_winning_no_bet > 0) ? (this.winningNo.total_bet / this.winningNo.total_winning_no_bet).toFixed(1) : '0.0';
            return (dynamic_odd);
        },
        getStandardOdd() {
            if (this.topHeaderDetails && this.topHeaderDetails.winning_car !== null) {
                switch (this.topHeaderDetails.winning_car) {
                    case 'ATV':
                        return 3.7;
                    case 'Car':
                        return 2.3;
                    case 'Monster Truck':
                        return 2.6;
                    case 'Motorcycle':
                        return 5.4;
                    case 'ORV':
                        return 2.3;
                    case 'SUV':
                        return 2.3;
                    case 'Sports Car':
                        return 3.4;
                    case 'Stock Car':
                        return 2.8;
                    case 'Supercar':
                        return 3.8;
                    default:
                        return '0';
                }
            } else {
                return '';
            }
        },
    },
    mounted() {
        this.contestId = this.$route.params.contest_id;
        this.userId = this.$route.params.user_id;
        this.carGameFetchBetHistoryDetails();
        this.setupScrollListener();
    },
    methods: {
        carGameFetchBetHistoryDetails() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/cargame/betdetailapi.php?page=${this.currentPage}&contest_id=${this.contestId}&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    this.carGameHistoryBetsDetails = [...this.carGameHistoryBetsDetails, ...response.data.data];
                    this.topHeaderDetails = response.data
                    this.winningNo = response.data
                    this.totalPages = response.data.total_pages;
                    this.checkAllImagesExist();

                    console.warn(apiUrl)
                })
                .catch(error => {
                    console.error('Error fetching car game history details data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        getCarImageSrc(car) {
            // Remove spaces from the car name
            const sanitizedCarName = car.replace(/\s+/g, '');
            return require(`../../assets/images/car-game/${sanitizedCarName}.png`);
        },
        checkAllImagesExist() {
            this.carGameHistoryBetsDetails.forEach(reward => {
                if (reward.image.length > 0) {
                    const imageUrl = reward.image;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.image = this.defaultProfile;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false &&  scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.carGameFetchBetHistoryDetails();
            }
        }
    }
}
</script>