<template>
    <div>
        <div class="listToggle topGame-topTabs">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" v-on:click="isHidden = false">
                    <div class="nav-link" :class="{ 'active': activeTab === 'luckyNo' }" @click="activateTab('luckyNo')"
                        data-toggle="tab" href="#luckyNo" role="tab">LUCKY NUMBER</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'zeepliveRace' }"
                        @click="activateTab('zeepliveRace')" data-toggle="tab" href="#zeepliveRace" role="tab">ZEEPLIVE
                        RACE</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true" style="display: none;">
                    <div class="nav-link" :class="{ 'active': activeTab === 'thimbles' }"
                        @click="activateTab('thimbles')" data-toggle="tab" href="#thimbles" role="tab">THIMBLES</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'zeepliveFlipCoin' }"
                        @click="activateTab('zeepliveFlipCoin')" data-toggle="tab" href="#zeepliveFlipCoin" role="tab">
                        FLIP COIN</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'TopGameMines' }"
                        @click="activateTab('TopGameMines')" data-toggle="tab" href="#TopGameMines" role="tab">Mines
                        Game</div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'luckyNo' }" id="luckyNo" role="tabpanel">
                <div class="game-bg">
                </div>
                <div v-if="isLoadedTopGameLuckyNumber">
                    <TopGameLuckyNumberTabs />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'zeepliveRace' }" id="zeepliveRace" role="tabpanel">
                <div class="game-bg">
                </div>
                <div v-if="isLoadedTopGameRace">
                    <TopGameRaceGameTabs />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'zeepliveFlipCoin' }" id="zeepliveFlipCoin"
                role="tabpanel">
                <div class="game-bg">
                </div>
                <div v-if="isLoadedTopGameFlipCoin">
                    <TopGameFlipCoinTabs />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'TopGameMines' }" id="TopGameMines" role="tabpanel">
                <div class="game-bg">
                </div>
                <div v-if="isLoadedTopGameMines">
                    <TopGameMinesTabs />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import TopGameCountDown from './TopGameCountDown.vue';
import TopGameLuckyNumberTabs from './TopGameLuckyNumberTabs.vue';
import TopGameRaceGameTabs from './TopGameRaceGameTabs.vue';
import TopGameFlipCoinTabs from './TopGameFlipCoinTabs.vue';
import TopGameMinesTabs from './TopGameMinesTabs.vue';
// import luckyNumberGameBG from '../../assets/images/lucky-number-game-bg.webp';
// import raceGameBG from '../../assets/images/race-game-bg.webp';
// import thimblesBG from '../../assets/images/thimbles-bg.webp';
// import flipCoinBG from '../../assets/images/flip_coin_rewards.webp';

export default {
    name: 'TopGames_2',
    components: {
        TopGameCountDown,
        TopGameLuckyNumberTabs,
        TopGameRaceGameTabs,
        TopGameFlipCoinTabs,
        TopGameMinesTabs
    },
    data() {
        return {
            activeTab: 'luckyNo',
            // luckyNumberGameBG: luckyNumberGameBG,    
            // raceGameBG: raceGameBG,
            // thimblesBG: thimblesBG,
            // flipCoinBG: flipCoinBG,
            isLoadedTopGameLuckyNumber: false,
            isLoadedTopGameRace: false,
            isLoadedTopGameThimbles: false,
            isLoadedTopGameFlipCoin: false,
            isLoadedTopGameMines: false,
        };
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'luckyNo' && !this.isLoadedTopGameLuckyNumber) {
                this.isLoadedTopGameLuckyNumber = true;
            }
            if (tabId === 'zeepliveRace' && !this.isLoadedTopGameRace) {
                this.isLoadedTopGameRace = true;
            }
            if (tabId === 'daily' && !this.isLoadedTopGameThimbles) {
                this.isLoadedTopGameThimbles = true;
            }
            if (tabId === 'zeepliveFlipCoin' && !this.isLoadedTopGameFlipCoin) {
                this.isLoadedTopGameFlipCoin = true;
            }
            if (tabId === 'TopGameMines' && !this.isLoadedTopGameMines) {
                this.isLoadedTopGameMines = true;
            }
        },
    },
    mounted() {
        const navLinks = this.$el.querySelectorAll(".nav-link");
        navLinks.forEach((tab) => {
            tab.addEventListener("click", () => {
                tab.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            });
        });
        this.isLoadedTopGameLuckyNumber = true;
    },
}
</script>