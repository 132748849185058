<template>
    <div>
        <div class="listToggle topGame-topTabs">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" v-on:click="isHidden = false">
                    <div class="nav-link" :class="{ 'active': activeTab === 'luckyNo' }" @click="activateTab('luckyNo')"
                        data-toggle="tab" href="#luckyNo" role="tab">LUCKY NUMBER</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'zeepliveRace' }"
                        @click="activateTab('zeepliveRace')" data-toggle="tab" href="#zeepliveRace" role="tab">ZEEPLIVE
                        RACE</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true" style="display: none;">
                    <div class="nav-link" :class="{ 'active': activeTab === 'thimbles' }"
                        @click="activateTab('thimbles')" data-toggle="tab" href="#thimbles" role="tab">THIMBLES</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'zeepliveFlipCoin' }"
                        @click="activateTab('zeepliveFlipCoin')" data-toggle="tab" href="#zeepliveFlipCoin" role="tab">
                        FLIP COIN</div>
                </li>
                <li class="nav-item" v-on:click="isHidden = true">
                    <div class="nav-link" :class="{ 'active': activeTab === 'zeepliveOpinionBet' }"
                        @click="activateTab('zeepliveOpinionBet')" data-toggle="tab" href="#zeepliveOpinionBet"
                        role="tab">OPINION BET</div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'luckyNo' }" id="luckyNo" role="tabpanel">
                <div class="game-bg">
                    <img class="main-bg" :src='luckyNumberGameBG' alt="Lucky Game Bg" />
                    <div class="countdown-boxes">
                        <TopGameCountDown />
                    </div>
                </div>
                <div v-if="isLoadedTopGameLuckyNumber">
                    <TopGameLuckyNumberTabs />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'zeepliveRace' }" id="zeepliveRace" role="tabpanel">
                <div class="game-bg">
                    <img class="main-bg" :src='raceGameBG' alt="Lucky Game Bg" />
                    <div class="countdown-boxes">
                        <TopGameCountDown />
                    </div>
                </div>
                <div v-if="isLoadedTopGameRace">
                    <TopGameRaceGameTabs />
                </div>
            </div>
            <!-- <div class="tab-pane" :class="{ 'active': activeTab === 'thimbles' }" id="daily" role="thimbles">
                <div class="game-bg">
                    <img class="main-bg" :src='thimblesBG' alt="Lucky Game Bg" />
                    <div class="countdown-boxes">
                        <TopGameCountDown />                        
                    </div>                                        
                </div>
                <div v-if="isLoadedTopGameThimbles">
                    <TopGameThimblesTabs />
                </div>
            </div> -->
            <div class="tab-pane" :class="{ 'active': activeTab === 'zeepliveFlipCoin' }" id="zeepliveFlipCoin"
                role="tabpanel">
                <div class="game-bg">
                    <img class="main-bg" :src='flipCoinBG' alt="Flip Coin Banner" />
                    <div class="countdown-boxes">
                        <TopGameCountDown />
                    </div>
                </div>
                <div v-if="isLoadedTopGameFlipCoin">
                    <TopGameFlipCoinTabs />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'zeepliveOpinionBet' }" id="zeepliveOpinionBet"
                role="tabpanel">
                <div class="game-bg">
                    <img class="main-bg" :src='opinionBetBG' alt="Opinion Bet Banner" />
                    <div class="countdown-boxes">
                        <TopGameCountDown />
                    </div>
                </div>
                <div v-if="isLoadedTopGameOpinionBet">
                    <TopGameOpinionBetTabs />
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import TopGameCountDown from './TopGameCountDown.vue';
import TopGameLuckyNumberTabs from './TopGameLuckyNumberTabs.vue';
import TopGameRaceGameTabs from './TopGameRaceGameTabs.vue';
// import TopGameThimblesTabs from './TopGameThimblesTabs.vue';
import TopGameFlipCoinTabs from './TopGameFlipCoinTabs.vue';
import TopGameOpinionBetTabs from './TopGameOpinionBetTabs.vue';
import luckyNumberGameBG from '../../assets/images/lucky-number-game-bg.webp';
import raceGameBG from '../../assets/images/race-game-bg.webp';
import thimblesBG from '../../assets/images/thimbles-bg.webp';
import flipCoinBG from '../../assets/images/flip_coin_rewards.webp';
import opinionBetBG from '../../assets/images/opinion_rewards.webp';

export default {
    name: 'TopGames',
    components: {
        TopGameCountDown,
        TopGameLuckyNumberTabs,
        TopGameRaceGameTabs,
        // TopGameThimblesTabs,
        TopGameFlipCoinTabs,
        TopGameOpinionBetTabs
    },
    data() {
        return {
            activeTab: 'luckyNo',
            luckyNumberGameBG: luckyNumberGameBG,
            raceGameBG: raceGameBG,
            thimblesBG: thimblesBG,
            flipCoinBG: flipCoinBG,
            opinionBetBG: opinionBetBG,
            isLoadedTopGameLuckyNumber: false,
            isLoadedTopGameRace: false,
            isLoadedTopGameThimbles: false,
            isLoadedTopGameFlipCoin: false,
            isLoadedTopGameOpinionBet: false,
        };
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'luckyNo' && !this.isLoadedTopGameLuckyNumber) {
                this.isLoadedTopGameLuckyNumber = true;
            }
            if (tabId === 'zeepliveRace' && !this.isLoadedTopGameRace) {
                this.isLoadedTopGameRace = true;
            }
            if (tabId === 'daily' && !this.isLoadedTopGameThimbles) {
                this.isLoadedTopGameThimbles = true;
            }
            if (tabId === 'zeepliveFlipCoin' && !this.isLoadedTopGameFlipCoin) {
                this.isLoadedTopGameFlipCoin = true;
            }
            if (tabId === 'zeepliveOpinionBet' && !this.isLoadedTopGameOpinionBet) {
                this.isLoadedTopGameOpinionBet = true;
            }
        },
    },
    mounted() {
        const navLinks = this.$el.querySelectorAll(".nav-link");
        navLinks.forEach((tab) => {
            tab.addEventListener("click", () => {
                tab.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            });
        });
        this.isLoadedTopGameLuckyNumber = true;
    },
}
</script>