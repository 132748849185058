<template>
    <div>
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="fixHeight luckyRewards-datalistWrap" ref="tableContainer">
            <div class="listBoxes" v-for="(reward, index) in LuckyRewardsYesterdayData" :key="index">
                <div class="leftBxb">
                    <div class="s-no" v-if="index > 2">{{ index + 1 }}</div>
                    <div class="s-no" v-else-if="index === 0">
                        <img :src='rankNoIcon1' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="s-no" v-else-if="index === 1">
                        <img :src='rankNoIcon2' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="s-no" v-else-if="index === 2">
                        <img :src='rankNoIcon3' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="listIconBox">
                        <div class="profPic">
                            <img :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                                alt="User Image" />
                            <!-- <img :src="reward.user.profile_images.length > 0 ? reward.user.profile_images[0].image_name : defaultUserImage" alt="User Image" /> -->
                        </div>
                    </div>
                    <div class="name-lavel-box">
                        <div class="userName">{{ reward.user.name }}</div>
                        <div class="lavel-box">
                            <img :src="getLevelImage(reward.user.level)" class="lavelImage" alt="level icon" />
                            <span class="lavel-value">{{ reward.user.level }}</span>
                        </div>
                    </div>
                </div>
                <div class="rightBox">
                    <div class="rightBox-in">
                        <div class="beansBox">
                            <img :src='diamondIcon' class="diamondIcon" alt="beans icons" />{{ reward.total_bet }}                            
                        </div>
                        <div class="userRewardsBox" v-if="reward.reward_coin > 0"><span>Reward</span>{{ reward.reward_coin }}</div>
                        
                    </div>
                </div>
            </div>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
        <div v-if="personalDetails" class="listBoxes myselfBox" 
        @click="redirectProfile(personalDetails.id, personalDetails.profile_id)" style="display: none !important">
            <div class="leftBxb">
                <div class="s-no">-</div>
                <div class="listIconBox">
                    <div class="profPic">
                            <img :src='(personalDetails.profile_images.length > 0) ? personalDetails.profile_images[0].image_name : defaultUserImage'
                            alt="User Image" />
                    </div>
                </div>
                <div class="name-lavel-box">
                    <div class="userName">{{ personalDetails.name }}</div>
                    <div class="lavel-box">
                        <img :src="getLevelImage(personalDetails.level)" class="lavelImage" alt="level icon" />
                        <span class="lavel-value">{{ personalDetails.level }}</span>
                    </div>
                </div>
            </div>
            <div class="rightBox">
                <div class="rightBox-in"><img :src='diamondIcon' class="diamondIcon" alt="diamond icons" />
                    {{ userTodaySpend }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import diamondIcon from '../../assets/images/diamond.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import rankNoIcon1 from '../../assets/images/rankno-icon-1.png';
import rankNoIcon2 from '../../assets/images/rankno-icon-2.png'
import rankNoIcon3 from '../../assets/images/rankno-icon-3.png'
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'LuckyRewardsLastWeek',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            LuckyRewardsYesterdayData: [],
            personalDetails: null,
            diamondIcon: diamondIcon,
            defaultUserImage: defaultUserImage,
            rankNoIcon1: rankNoIcon1,
            rankNoIcon2: rankNoIcon2,
            rankNoIcon3: rankNoIcon3,
            loading: false,
            scrollLoading: false,
        }
    },
    mounted() {
        this.profile_id = this.$route.params.profile_id;
        this.fetchLuckyRewardsYesterdayData();
        this.setupScrollListener();
    },
    
    methods: {
        fetchLuckyRewardsYesterdayData() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/user-luckygame-daily-rewards-popup-api?profile_id=${this.profile_id}`;
            axios.get(apiUrl)
                .then(response => {
                    // this.LuckyRewardsYesterdayData = response.data.result.yesterday;
                    this.LuckyRewardsYesterdayData = [...this.LuckyRewardsYesterdayData, ...response.data.result.yesterday];
                    this.personalDetails = response.data.result.user_today;
                    this.userTodaySpend = response.data.result.user_today_spend;
                    this.UserDataInfo = response.data.result.userdata;
                    this.checkAllImagesExist();
                })
                .catch(error => {
                    console.error("Error Featching Lucky Rewards Yesterday Data", error)
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        getLevelImage(level) {
            switch (true) {
                case level === 0:
                    return require('@/assets/images/charm-lavel-0.png');
                case level === 1:
                    return require('@/assets/images/charm-lavel-1.png');
                case level === 2:
                    return require('@/assets/images/charm-lavel-2.png');
                case level === 3:
                    return require('@/assets/images/charm-lavel-3.png');
                case level === 4:
                    return require('@/assets/images/charm-lavel-4.png');
                case level === 5:
                    return require('@/assets/images/charm-lavel-5.png');
                case level >= 6 && level <= 10:
                    return require('@/assets/images/charm-lavel-6-10.png');
                case level >= 11 && level <= 15:
                    return require('@/assets/images/charm-lavel-11-15.png');
                case level >= 16 && level <= 20:
                    return require('@/assets/images/charm-lavel-16-20.png');
                default:
                    return require('@/assets/images/charm-lavel-0.png');
            }
        },
        checkAllImagesExist() {
            this.LuckyRewardsYesterdayData.forEach(reward => {
            if (reward.user.profile_images.length > 0) {
                const imageUrl = reward.user.profile_images[0].image_name;
                this.checkImageExists(imageUrl)
                    .then(exists => {
                        if (!exists) {
                            reward.user.profile_images[0].image_name = this.defaultUserImage;
                        }
                    });
            }
        });
    },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if (scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchLuckyRewardsYesterdayData();
            }
        },
        redirectProfile(id, profile_id) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                const baseURL = 'female-weekly-rewards';
                const params = {
                    id: id,
                    profile_id: profile_id
                };
                const queryString = Object.keys(params)
                    .map(key => `${key}=${params[key]}`)
                    .join('&');
                const url = `${baseURL}?${queryString}`;
                //   window.location.href = url;
                console.warn(url)
                Android.redirectProfile(url);
            }
        }
    },
}
</script>