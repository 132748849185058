<template>
  <div class="outerWrapper">
    <router-view />
  </div>
</template>

<script>
import './assets/css/style.css';
import './assets/css/luckyNumber.css'
import './assets/fontsSegoe/stylesheet.css'
export default {
  name: 'App',
  components: {
  },
  // methods: {
  //   setViewportHeight() {
  //     const vh = window.innerHeight * 0.01;
  //     document.documentElement.style.setProperty('--vh', `${vh}px`);
  //   },
  // },
  // mounted() {
  //   // Set the initial value
  //   this.setViewportHeight();

  //   // Add event listeners for resize and scroll
  //   window.addEventListener('resize', this.setViewportHeight);
  //   window.addEventListener('scroll', this.setViewportHeight);
  // },
  // beforeUnmount() {
  //   // Remove event listeners
  //   window.removeEventListener('resize', this.setViewportHeight);
  //   window.removeEventListener('scroll', this.setViewportHeight);
  // },
}
</script>