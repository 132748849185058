<template>
    <div>
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="fixHeight luckyRewards-datalistWrap" ref="tableContainer">
            <div class="listBoxes" v-for="(reward, index) in CarRankThisWeekData" :key="index">
                <div class="leftBxb">
                    <div class="s-no" v-if="index > 2">{{ index + 1 }}</div>
                    <div class="s-no" v-else-if="index === 0">
                        <img :src='rankNoIcon1' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="s-no" v-else-if="index === 1">
                        <img :src='rankNoIcon2' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="s-no" v-else-if="index === 2">
                        <img :src='rankNoIcon3' class="rankNumbImage" alt="Rank Icon" />
                    </div>
                    <div class="listIconBox">
                        <div class="profPic">
                            <img :src='(reward.image.length > 0) ? reward.image : defaultUserImage' alt="User Image" />
                        </div>
                    </div>
                    <div class="name-lavel-box">
                        <div class="userName">{{ reward.name }}</div>
                        <div class="lavel-box">
                            <img :src='yellowLabelIconBGImage' class="lavelImage" alt="level icon" />
                            <span class="lavel-value">{{ reward.rich_level }}</span>
                        </div>
                    </div>
                </div>
                <div class="rightBox">
                    <div class="rightBox-in">
                        <img :src='diamondIcon' class="diamondIcon" alt="beans icons" />{{ reward.total_bet }}
                    </div>
                </div>
            </div>
            <div v-if="scrollLoading" class="scrollLoading rankScrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import LoadingOverlay from '../LoadingOverlay.vue'
import diamondIcon from '../../assets/images/diamond.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import yellowLabelIconBGImage from '../../assets/images/yellow-label-bg.png';
import rankNoIcon1 from '../../assets/images/rankno-icon-1.png';
import rankNoIcon2 from '../../assets/images/rankno-icon-2.png'
import rankNoIcon3 from '../../assets/images/rankno-icon-3.png'
export default {
    name: 'CarGameRankThisWeek',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            CarRankThisWeekData: [],
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1,
            diamondIcon: diamondIcon,
            defaultUserImage: defaultUserImage,
            yellowLabelIconBGImage: yellowLabelIconBGImage,
            rankNoIcon1: rankNoIcon1,
            rankNoIcon2: rankNoIcon2,
            rankNoIcon3: rankNoIcon3,
        }
    },
    mounted() {
        this.profile_id = this.$route.params.profile_id;
        this.fetchCarRankThisWeekData();
        this.setupScrollListener();
    },
    // beforeDestroy() {
    //     this.removeScrollListener();
    // },

    methods: {
        fetchCarRankThisWeekData() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/cargame/rank_weakly_api.php?page=${this.currentPage}&type=1`;
            axios.get(apiUrl)
                .then(response => {
                    this.CarRankThisWeekData = [...this.CarRankThisWeekData, ...response.data.data];
                    this.totalPages = response.data.total_pages;
                    this.checkAllImagesExist();
                })
                .catch(error => {
                    console.error("Error Featching This week Car Rank Data", error)
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },

        checkAllImagesExist() {
            this.CarRankThisWeekData.forEach(reward => {
                if (reward.image.length > 0) {
                    const imageUrl = reward.image;
                    this.checkImageExists(imageUrl)
                        .then(exists => {
                            if (!exists) {
                                reward.image = this.defaultUserImage;
                            }
                        });
                }
            });
        },
        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;
            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchCarRankThisWeekData();
            }
        }
    },
}
</script>