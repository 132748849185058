<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="daily-reward-box rewardPhoto">
         <div class="wrapDiv">
            <div class="coin-box">
               <div class="coin-text">
                  <div class="text-white" @click="redirectProfile(lastWeekData[1].user.id, lastWeekData[1].user.profile_id, lastWeekData[1].user.charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img :src='zhibojianFansSecond' alt="Rank 2 Section Image">
                           <div class="coin-profPic" v-if="lastWeekData.length > 1">
                              <img class="overlapImg" :src='lastWeekData[1].user.profile_images[0].image_name'
                                 alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank2' alt="Rank 2">
                        <div class="pointsBox">
                           <img class="points" :src='TCLabelBG' alt="Label Image">
                           <span class="value" v-if="lastWeekData.length > 1">{{ lastWeekData[1].user.charm_level
                              }}</span>
                        </div>
                     </div>
                     <div class="userInfo-box" v-if="lastWeekData.length > 1" style="margin-top: 10px !important;">
                        <span class="name">
                           <div :class="{ 'marqueeContainer': lastWeekData[1].user.name.length > 8 }">
                              <div v-if="lastWeekData[1].user.name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ lastWeekData[1].user.name }}</span>
                              </div>
                              <div v-else>{{ lastWeekData[1].user.name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(lastWeekData[1].total_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards">Reward
                           <span class="rewardsCoinVal">{{ lastWeekData[1].reward_coin }}</span>
                        </span>
                        <span class="rewards ne-btn" v-if="lastWeekData[1].call_performance <= 40" style="margin-left: 10px;">Not Eligible - {{ lastWeekData[1].call_performance }}</span>
                     </div>
                  </div>
               </div>
               <div class="coin-text active">
                  <div class="text-white" @click="redirectProfile(lastWeekData[0].user.id, lastWeekData[0].user.profile_id, lastWeekData[0].user.charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img class="activeZoom" :src='zhibojianFansFirst' alt="" />
                           <div class="coin-profPicActive" v-if="lastWeekData.length > 0">
                              <img class="overlapImg overlapActive"
                                 :src='lastWeekData[0].user.profile_images[0].image_name' alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank1' alt="Rank 1" />
                        <div class="pointsBox" v-if="lastWeekData.length > 0">
                           <img class="points" :src='TCLabelBG' alt="Label Image">
                           <span class="value">{{ lastWeekData[0].user.charm_level }}</span>
                        </div>
                     </div>
                     <div class="userInfo-box" v-if="lastWeekData.length > 0" style="margin-top: 10px !important;">
                        <span class="name" style="text-align: center;">
                           <div :class="{ 'marqueeContainer': lastWeekData[0].user.name.length > 8 }">
                              <div v-if="lastWeekData[0].user.name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ lastWeekData[0].user.name }}</span>
                              </div>
                              <div v-else>{{ lastWeekData[0].user.name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box" style="text-align: center;">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(lastWeekData[0].total_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards">Reward
                           <span class="rewardsCoinVal">{{ lastWeekData[0].reward_coin }}</span>
                        </span>
                        <span class="rewards ne-btn" v-if="lastWeekData[0].call_performance <= 40" style="margin-left: 10px;">Not Eligible - {{ lastWeekData[0].call_performance }}</span>
                     </div>
                  </div>
               </div>
               <div class="coin-text">
                  <div class="text-white" @click="redirectProfile(lastWeekData[2].user.id, lastWeekData[2].user.profile_id, lastWeekData[2].user.charm_level)">
                     <div class="img-img">
                        <div class="overlap">
                           <img :src='zhibojianFansThird' alt="" />
                           <div class="coin-profPic" v-if="lastWeekData.length > 2">
                              <img class="overlapImg" :src='lastWeekData[2].user.profile_images[0].image_name'
                                 alt="User Image" />
                           </div>
                        </div>
                        <img class="smallpoint" :src='rank3' alt="Rank 3" />
                        <div class="pointsBox" v-if="lastWeekData.length > 2">
                           <img class="points" :src='TCLabelBG' alt="Label Image" />
                           <span class="value">{{ lastWeekData[2].user.charm_level }}</span>
                        </div>
                     </div>
                     <div class="userInfo-box" v-if="lastWeekData.length > 2" style="margin-top: 10px !important;">
                        <span class="name">
                           <div :class="{ 'marqueeContainer': lastWeekData[2].user.name.length > 8 }">
                              <div v-if="lastWeekData[2].user.name.length > 8" class="marquee">
                                 <span class="marquee-content">{{ lastWeekData[2].user.name }}</span>
                              </div>
                              <div v-else>{{ lastWeekData[2].user.name }}</div>
                           </div>
                        </span>
                        <span class="spanBox beans-box">
                           <img :src='yellowClockIcon' alt="Clock Icon" />
                           {{ Math.floor(lastWeekData[2].total_duration / 60) }}
                           <span class="minTxt">min</span>
                        </span>
                        <span class="rewards">Reward
                           <span class="rewardsCoinVal">{{ lastWeekData[2].reward_coin }}</span>
                        </span>
                        <span class="rewards ne-btn" v-if="lastWeekData[2].call_performance <= 40" style="margin-left: 10px;">Not Eligible - {{ lastWeekData[2].call_performance }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="podium">
               <img class="podiumImg" :src='TCPodiumBG' alt="Podium Image" />
            </div>
         </div>
      </div>
      <div class="listingPattern">
         <div class="fixHeight">
            <div class="listBoxes" v-for="(reward, index) in lastWeekData.slice(3)" :key="reward.id"
            @click="redirectProfile(reward.user.id, reward.user.profile_id, reward.user.charm_level)">
               <div class="leftBxb">
                  <h4>{{ index + 4 }}</h4>
                  <div class="listIconBox">
                     <div class="profPic">
                        <img
                           :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                           class="user" alt="User Image" />
                     </div>
                     <img class="frames" :src='reward.user.last_pic_frame' alt="" />
                  </div>
                  <span>
                     <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                        <div v-if="reward.user.name.length > 8" class="marquee">
                           <span class="marquee-content">{{ reward.user.name }}</span>
                        </div>
                        <div v-else>{{ reward.user.name }}</div>
                     </div>

                     <div class="base">
                        <img :src='TCLabelBG' alt="" /><b>{{ reward.user.charm_level }}</b>
                     </div>
                  </span>
               </div>
               <div class="rightBxb">
                  <span>
                     <img :src='yellowClockIcon' alt="Clock Icon" />
                     {{ Math.floor(reward.total_duration / 60) }}<span class="minTxt">min</span> <br />
                     <span class="rewards" v-if="index < 7">Reward {{ reward.reward_coin }}</span>
                  </span>
                  <div class="rewards ne-btn" v-if="reward.call_performance <= 40" style="margin-left: 10px;">Not Eligible - {{ reward.call_performance }}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios';
import TCPodiumBG from '../../assets/images/TC-podium-bg.png';
import yellowClockIcon from '../../assets/images/yellowClock-Icon.png'
import zhibojianFansSecond from '../../assets/images/zhibojian_fans_sec.png';
import zhibojianFansFirst from '../../assets/images/zhibojian_fans_first.png';
import zhibojianFansThird from '../../assets/images/zhibojian_fans_thi.png';
import rank1 from '../../assets/images/rank-1.png'
import rank2 from '../../assets/images/rank-2.png'
import rank3 from '../../assets/images/rank-3.png'
import TCLabelBG from '../../assets/images/TC-label-bg.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopCallLastWeekTabCont',
   components: {
      LoadingOverlay
   },
   data() {
      return {
         isRewardPopupVisible: false,
         lastWeekData: [],
         TCPodiumBG: TCPodiumBG,
         yellowClockIcon: yellowClockIcon,
         zhibojianFansFirst,
         zhibojianFansSecond,
         zhibojianFansThird,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         TCLabelBG: TCLabelBG,
         defaultUserImage: defaultUserImage,
         loading: false
      }
   },
   mounted() {
      this.fetchWeeklyRewards();
   },
   methods: {
      fetchWeeklyRewards() {
         this.loading = true;
         axios.get('api/top-call-weekly?type=last_week').then(response => {
            this.lastWeekData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error('Enter featching last week data:', error)
            })
            .finally(() => {
                    this.loading = false;
                })
      },
      showRewardPopup() {
         this.isRewardPopupVisible = true;
      },
      hideRewardPopup() {
         this.isRewardPopupVisible = false;
      },
      checkAllImagesExist() {
         this.lastWeekData.forEach(reward => {
            if (reward.user.profile_images.length > 0) {
               const imageUrl = reward.user.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.user.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },
      // Get User info on click 
      redirectProfile(id, profile_id, level) {
         // if (window.navigator.userAgent.indexOf("Android") > -1) {
         // const baseURL = 'female-weekly-rewards';
         const baseURL = '#';
         const params = {
            id: id,
            profile_id: profile_id,
            level: level
         };

         const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
         const url = `${baseURL}?${queryString}`;
         window.location.href = url;
         // Android.redirectProfile(url);
         // }
      }
   }
}
</script>
<style>
.rewards.ne-btn {
   display: inline-block;
   background: #fff;
   font-size: 9px !important;
   color: #FF7700 !important;
   padding: 3px 6px;
   margin-top: 2px;
   border-radius: 25px;
   font-weight: 700;
}

.topCall .listBoxes .rightBxb span .rewards.ne-btn {
   top: -3px;
}
</style>