<template>
    <div>
        <div class="listToggle awwardsLead">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'today' }" @click="activateTab('today')" data-toggle="tab" href="#today" role="tab">Today</div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'yesterday' }" @click="activateTab('yesterday')" data-toggle="tab" href="#yesterday" role="tab">Yesterday</div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'today' }" id="today" role="tabpanel">
                <div class="rewards-bg">
                    <div v-if="isLoadedToday">
                        <TopGameRaceGameTodayTabCont />
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'yesterday' }" id="yesterday" role="tabpanel">
                <div class="rewards-bg">
                    <div v-if="isLoadedYesterday">
                        <TopGameRaceGameYesterdayTabCont />  
                    </div>                  
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TopGameRaceGameTodayTabCont from './TopGameRaceGameTodayTabCont.vue'
import TopGameRaceGameYesterdayTabCont from './TopGameRaceGameYesterdayTabCont.vue'
export default {
    name: 'TopGameRaceGameTabs',
    components: {
        TopGameRaceGameTodayTabCont,
        TopGameRaceGameYesterdayTabCont
    },
    data() {
        return {
            activeTab: 'today',
            isLoadedToday: false,
            isLoadedYesterday: false
        };
    },
    mounted () {
        this.isLoadedToday = true;
    },
    methods: {  
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'today' && !this.isLoadedToday) {
                this.isLoadedToday = true;
            }
            if (tabId === 'yesterday' && !this.isLoadedYesterday) {
                this.isLoadedYesterday = true;
            }
        },
    }
}
</script>
