<template>
    <div>
        <div class="listToggle awwardsLead">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" @click="showTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'thisweek' }"
                        @click="activateTab('thisweek')" data-toggle="tab" href="#thisweek" role="tab">This Week</div>
                </li>
                <li class="nav-item" @click="hideTimesBox">
                    <div class="nav-link" :class="{ 'active': activeTab === 'lastweek' }"
                        @click="activateTab('lastweek')" data-toggle="tab" href="#lastweek" role="tab">Last Week</div>
                </li>
            </ul>
            <div class="timesBox" v-show="isTimesBoxVisible">
                <CounterdownCompo :isVisible="true" :showDays="true" />
            </div>
        </div>

        <div class="rewards-bg">
            <h3 class="txtb">{{ activeTab === 'thisweek' ? 'This Week' : 'Last Week' }}</h3>
            <img class="awwwards-icon" :src='TAAwardsIcon' alt="Awwwards icon"
                @click="showRewardPopup" />
            <img class="date-bg" :src='topAgencyBarBG' alt="Rich Bar Background" />
            <div class="tab-content">
                <div class="tab-pane" :class="{ 'active': activeTab === 'thisweek' }" id="thisweek" role="tabpanel">
                    <div class="listingPattern">
                        <TopAgencyThisWeekListDetailsComp />
                    </div>
                </div>
                <div class="tab-pane" :class="{ 'active': activeTab === 'lastweek' }" id="lastweek" role="tabpanel">
                    <div class="listingPattern">
                        <TopAgencyLastWeekListDetailsComp />
                    </div>
                </div>
            </div>
        </div>
        <!-- Reward Popup -->
        <div class="rewardPopupWrapper" v-show="isRewardPopupVisible">
            <div class="rewardPopupInner scale-up-bottom">
                <h4 class="h4">CURRENT REWARD</h4>
                <img :src='popupClosed' class="closed" @click="hideRewardPopup">
                <div class="rewardPopupContBox">
                    <div class="coin-box">
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown2' alt="Reward Rank 2">
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="currentRewardData.length > 1">{{ currentRewardData[1].reward_coin
                                }}</span>
                            </span>
                        </div>
                        <div class="coin-text active">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown1' alt="Reward Rank 2">
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="currentRewardData.length > 0">{{ currentRewardData[0].reward_coin
                                }}</span>
                            </span>
                        </div>
                        <div class="coin-text">
                            <div class="img-img">
                                <div class="overlap">
                                    <img :src='crown3' alt="Reward Rank 2">
                                </div>
                            </div>
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon">
                                <span v-if="currentRewardData.length > 2">{{ currentRewardData[2].reward_coin
                                }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="podium">
                        <img class="podiumImg" :src='yellowPodiumBG' alt="Yellow Podium icon">
                    </div>
                    <div class="weeklyBoth">
                        <div class="rank-box">
                            <span>4th</span>
                            <div class="righticon">
                                <span class="spanBox">
                                    <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                    <span v-if="currentRewardData.length > 3">{{ currentRewardData[3].reward_coin
                                }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="rank-box">
                            <span>5th</span>
                            <div class="righticon">
                                <span class="spanBox">
                                    <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                    <span v-if="currentRewardData.length > 4">{{ currentRewardData[4].reward_coin
                                }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="rank-box">
                        <span>6th to 10th</span>
                        <div class="righticon">
                            <span class="spanBox">
                                <img :src='beansIcon' class="beansIcon" alt="Beans icon" />
                                <span v-if="currentRewardData.length > 5">{{ currentRewardData[5].reward_coin
                                }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Reward Popup End -->
    </div>
</template>
<script>
import axios from 'axios';
import TopAgencyThisWeekListDetailsComp from './TopAgencyThisWeekListDetailsComp.vue';
import TopAgencyLastWeekListDetailsComp from './TopAgencyLastWeekListDetailsComp.vue';
import CounterdownCompo from '../../components/CounterdownCompo.vue';
import TAAwardsIcon from '../../assets/images/TA-awards-icon.png';
import topAgencyBarBG from '../../assets/images/topAgency-bar-bg.png';
import popupClosed from '../../assets/images/popupClosed.svg';
import crown1 from '../../assets/images/crown_1.png';
import crown2 from '../../assets/images/crown_2.png';
import crown3 from '../../assets/images/crown_3.png';
import beansIcon from '../../assets/images/beans-icon.png';
import yellowPodiumBG from '../../assets/images/yellow-podium-bg.png'
export default {
    name: 'TopAgency',
    components: {
        TopAgencyThisWeekListDetailsComp,
        TopAgencyLastWeekListDetailsComp,
        CounterdownCompo
    },
    data() {
        return {
            isTimesBoxVisible: true,
            activeTab: 'thisweek',
            isRewardPopupVisible: false,
            currentRewardData: [],
            TAAwardsIcon: TAAwardsIcon,
            topAgencyBarBG: topAgencyBarBG,
            popupClosed: popupClosed,
            crown1: crown1,
            crown2: crown2,
            crown3: crown3,
            beansIcon: beansIcon,
            yellowPodiumBG: yellowPodiumBG
        };
    },
    mounted() {
        this.fetchWeeklyCurrentReward();
    },
    methods: {
        showTimesBox() {
            this.isTimesBoxVisible = true;
        },
        hideTimesBox() {
            this.isTimesBoxVisible = false;
        },
        activateTab(tabId) {
            this.activeTab = tabId;
        },
        showRewardPopup() {
            this.isRewardPopupVisible = true;
        },
        hideRewardPopup() {
            this.isRewardPopupVisible = false;
        },
        fetchWeeklyCurrentReward() {
            axios.get('api/top-agency-weekly').then(response => {
                this.currentRewardData = response.data.result.weeklyrewards;
            })
                .catch(error => {
                    console.error("Error Featching Current Rewards Data", error)
                })
        }
    },
}
</script>