<template>
    <div class="mainTabs-wrapper" :class="{ 'spaced-header': redirected }">
        <div id="parentTabIn" class="parentTabIn">
            <div class="backArrowIcon" id="close" @click="viewClose('someStatus')">
                <img :src='downArrow' alt="Back Arrow" />
            </div>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topPkTab' }"
                        @click="activateTab('topPkTab')" data-toggle="tab" href="#topPkTab" role="tab">Top PK
                    </div>
                </li>
                <li class="nav-item" style="display: none;">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topCharmTab' }"
                        @click="activateTab('topCharmTab')" data-toggle="tab" href="#topCharmTab" role="tab">Top Charm
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topRichTab' }"
                        @click="activateTab('topRichTab')" data-toggle="tab" href="#topRichTab" role="tab">Top Rich
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topCallTab' }"
                        @click="activateTab('topCallTab')" data-toggle="tab" href="#topCallTab" role="tab">Top Call
                    </div>
                </li>
                <li class="nav-item" style="display: none;">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topGameTab' }"
                        @click="activateTab('topGameTab')" data-toggle="tab" href="#topGameTab" role="tab">Top Games
                    </div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'topGameTab_2' }"
                        @click="activateTab('topGameTab_2')" data-toggle="tab" href="#topGameTab_2" role="tab">Top Games
                    </div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'topPkTab' }" id="topPkTab" role="tabpanel">
                <div class="tab-contentIn topPk">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopPk">
                            <TopPk />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topCharmTab' }" id="topCharmTab" role="tabpanel">
                <div class="tab-contentIn topCharm">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopCharm">
                            <TopCharm />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topRichTab' }" id="topRichTab" role="tabpanel">
                <div class="tab-contentIn topRich">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopRich">
                            <TopRich />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topCallTab' }" id="topCallTab" role="tabpanel">
                <div class="tab-contentIn topCall">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopCall">
                            <TopCall />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topGameTab' }" id="topGameTab" role="tabpanel">
                <div class="tab-contentIn topGames">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopGames">
                            <TopGames />
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'topGameTab_2' }" id="topGameTab_2" role="tabpanel">
                <div class="tab-contentIn topGames topGame_2_tabContentBox">
                    <div class="mainWrapper">
                        <div class="component-box" v-if="isLoadedTopGames">
                            <TopGames_2 />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import downArrow from '../../assets/images/downArrow.png';
import TopPk from '../../components/TopPk/TopPk.vue'
import TopCharm from '../../components/TopCharm/TopCharm.vue'
import TopRich from '../../components/TopRich/TopRich.vue'
import TopCall from '../../components/TopCall/TopCall.vue'
import TopAgency from '../../components/TopAgency/TopAgency.vue'
import TopGames from '../../components/TopGames/TopGames.vue'
import TopGames_2 from '../../components/TopGames_2/TopGames_2.vue'
export default {
    name: 'ParentTabComponent',
    components: {
        TopPk,
        TopCharm,
        TopRich,
        TopCall,
        TopAgency,
        TopGames,
        TopGames_2
    },
    data() {
        return {
            activeTab: 'topPkTab',
            isParentTabVisible: true,
            downArrow: downArrow,
            isLoadedTopPk: false,
            isLoadedTopCharm: false,
            isLoadedTopRich: false,
            isLoadedTopCall: false,
            isLoadedTopGames: false,
            redirected: false,

            queryValue: null,
        };
    },
    created() {

        const currentPath = this.$route.path; // Get the current route path
        if (currentPath === '/topGame2') {
            this.activeTab = 'topGameTab_2';
            this.isLoadedTopGames = true; // Ensure the data is marked as loaded
        }
        // Get the query parameter value from the URL when the component is created
        const urlParams = new URLSearchParams(window.location.search);
        this.queryValue = urlParams.get('redirectedWeekly'); // Replace 'yourParameterName' with the actual parameter name

        if (this.queryValue === 'true') {
            this.redirected = true;
        }
        //alert(this.queryValue)
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'topPkTab' && !this.isLoadedTopPk) {
                this.isLoadedTopPk = true;
            }
            if (tabId === 'topCharmTab' && !this.isLoadedTopCharm) {
                this.isLoadedTopCharm = true;
            }
            if (tabId === 'topRichTab' && !this.isLoadedTopRich) {
                this.isLoadedTopRich = true;
            }
            if (tabId === 'topCallTab' && !this.isLoadedTopCall) {
                this.isLoadedTopCall = true;
            }
            if (tabId === 'topGameTab' && !this.isLoadedTopGames) {
                this.isLoadedTopGames = true;
            }
            if (tabId === 'topGameTab_2' && !this.isLoadedTopGames) {
                this.isLoadedTopGames = true;
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        }
    },
    mounted() {
        const navLinks = this.$el.querySelectorAll(".nav-link");
        navLinks.forEach((tab) => {
            tab.addEventListener("click", () => {
                tab.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            });
        });
        this.isLoadedTopPk = true;
    },
}
</script>