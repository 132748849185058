<template>
   <div>
      <LoadingOverlay :loading="loading" />
      <div class="fixHeight">
         <div class="listBoxes" v-for="(reward, index) in lastWeekData" :key="reward.id"
         @click="redirectProfile(reward.user_id, reward.profile_id, reward.charm_level)">
            <div class="leftBxb">
               <h4 v-if="index > 2">{{ index + 1 }}</h4>
               <img v-else-if="index === 0" :src='rank1' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 1" :src='rank2' class="rankNumbImage" alt="Reward Rank Image" />
               <img v-else-if="index === 2" :src='rank3' class="rankNumbImage" alt="Reward Rank Image" />
               <div class="listIconBox">
                  <div class="profPic">
                     <img
                        :src='(reward.user.profile_images.length > 0) ? reward.user.profile_images[0].image_name : defaultUserImage'
                        class="user" alt="User Image" />
                  </div>
                  <img class="frames" :src='reward.user.last_pic_frame' alt="Profile Frame" />
               </div>
               <span>
                  <div :class="{ 'marqueeContainer': reward.user.name.length > 8 }">
                     <div v-if="reward.user.name.length > 8" class="marquee">
                        <span class="marquee-content">{{ reward.user.name }}</span>
                     </div>
                     <div v-else>{{ reward.user.name }}</div>
                  </div>
                  <div class="base">
                     <img :src='reward.user.last_rich_level_icon' /><b>{{ reward.user.rich_level }}</b>
                  </div>
               </span>
            </div>
            <div class="rightBxb">
               <span>
                  <img :src='diamondIcon' alt="Diamond Icon" />{{ reward.total_coin_spend }} <br />
                  <span class="rewards" v-if="index < 10">Reward {{ reward.reward_coin }}</span>
               </span>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import axios from 'axios'
import diamondIcon from '../../assets/images/diamond.png';
import rank1 from '../../assets/images/rank-1.png';
import rank2 from '../../assets/images/rank-2.png'
import rank3 from '../../assets/images/rank-3.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
   name: 'TopRichLastWeekListDetailsComp',
   components: {
        LoadingOverlay
    },
   data() {
      return {
         lastWeekData: [],
         diamondIcon: diamondIcon,
         rank1: rank1,
         rank2: rank2,
         rank3: rank3,
         defaultUserImage: defaultUserImage,
         loading: false,
      }
   },
   mounted() {
      this.fetchWeeklyRewards();
   },
   methods: {
      fetchWeeklyRewards() {
         this.loading = true;
         axios.get('api/top-rich-weekly?type=last_week').then(response => {
            this.lastWeekData = response.data.result.data;
            this.checkAllImagesExist();
         })
            .catch(error => {
               console.error('Error fetching weekly rewards:', error);
            })
            .finally(() => {
                    this.loading = false;
                })
      },
      checkAllImagesExist() {
         this.loading = true;
         this.lastWeekData.forEach(reward => {
            if (reward.user.profile_images.length > 0) {
               const imageUrl = reward.user.profile_images[0].image_name;
               this.checkImageExists(imageUrl)
                  .then(exists => {
                     if (!exists) {
                        reward.user.profile_images[0].image_name = this.defaultUserImage;
                     }
                  });
            }
         });
      },
      checkImageExists(imageUrl) {
         return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
               resolve(true);
            };
            img.onerror = function () {
               resolve(false);
            };
            img.src = imageUrl;
         });
      },

      redirectProfile(id, profile_id, level) {
         // if (window.navigator.userAgent.indexOf("Android") > -1) {
         // const baseURL = 'female-weekly-rewards';
         const baseURL = '#';
         const params = {
            id: id,
            profile_id: profile_id,
            level: level
         };

         const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
         const url = `${baseURL}?${queryString}`;
         window.location.href = url;
         // Android.redirectProfile(url);
         // }
      }
   },
}
</script>