<template>
    <div class="luckyRewards-wrapper spaceCraftWrapper">
        <div class="backgroundWrapper">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">Win Rate Rank</h2>
                    </div>
                </div>
            </div>
            <div class="winRateRank-listIn spaceCraft-winRank">
                <ul>
                    <li v-for="bet in SpaceCraftWinRankData" :key="bet.contestid">
                        <div class="car-imgBox">
                            <img :src="getSpaceCraftImageSrc(bet.winning_spacecraft)" class="car-img img-fluid"
                                :alt="bet.winning_spacecraft" />
                        </div>
                        <div class="carName spaceCraftName">{{ bet.winning_spacecraft }}</div>
                        <div class="carRateBoxs">
                            <div class="carRate-percBox">{{ parseFloat(bet.winning_percentage).toFixed(2) }}%</div>
                            <div class="carRatingBox"> {{getStandardOdds (bet.winning_spacecraft)  }}</div>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'SpaceCraftGameWinRank',
    data() {
        return {
            SpaceCraftWinRankData: [],
        }
    },
    mounted() {
        this.FetchSpaceCraftWinRankData();
    },
    methods: {
        async FetchSpaceCraftWinRankData() {
            const apiUrl = `api/spacecrafts-game/winrate_rank_api.php`;
            try {
                const response = await axios.get(apiUrl);
                if (response.data && response.data.result) {
                    const result = response.data.result;
                    this.SpaceCraftWinRankData = result.data;
                    this.spacecraftImages = result.spacecraftsImageData;
                    this.standardOdds = result.standardOddsData;
                    // console.log('Data ---',this.SpaceCraftWinRankData)
                }
            } catch (error) {
                console.error("Error fetching win rank data:", error);
            }
        },
        // getSpaceCraftImageSrc(spaceCraft) {
        //     const sanitizedSpaceCraftName = spaceCraft.replace(/\s+/g, "");
        //     return this.spacecraftImages[sanitizedSpaceCraftName]
        //         ? require(`../../assets/images/spaceCrafts/${this.spacecraftImages[sanitizedSpaceCraftName]}`)
        //         : require("../../assets/images/spaceCrafts/AlienReaver.png");
        // },
        getSpaceCraftImageSrc(spaceCraft) {
            const sanitizedSpaceCraftName = spaceCraft.replace(/\s+/g, '');
            return require(`../../assets/images/spaceCrafts/${sanitizedSpaceCraftName}.png`);
        },
        getStandardOdds(spaceCraft) {
            return this.standardOdds[spaceCraft] || "N/A";
        },
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        },
    },
}
</script>