<template>
    <div>
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="statistics-listWrap" ref="tableContainer">
            <div class="statistics-listBox" v-for="(group, index) in bigSmallData" :key="index">
                <div v-if="group.length > 12" class="statistics-list statisticsMarquee">
                    <span v-for="(item, itemIndex) in group" :key="itemIndex">{{ item }}</span>
                </div>
                <div class="statistics-list" v-else>
                    <span v-for="(item, itemIndex) in group" :key="itemIndex">{{ item }}</span>
                </div>
            </div>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'LuckyStatisticsBS',
    components: {
        LoadingOverlay
    },
    data() {
        return {
            bigSmallData: [],
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1
        }
    },
    mounted() {
        this.userId = this.$route.params.user_id;
        this.fetchBigSmallData();
        this.setupScrollListener();
    },
    methods: {
        fetchBigSmallData() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/betapi/betstatisticsapi.php?page=${this.currentPage}&type=BS&user_id=${this.userId}`;
            axios.get(apiUrl)
                .then(response => {
                    const newData = response.data.data.map(item => item.small_big); // Extract small_big values from fetched data
                    const groupedData = this.groupAdjacentItems(newData); // Group adjacent items

                    if (this.currentPage === 1) {
                        this.bigSmallData = groupedData; // Replace existing data on first page load
                    } else {
                        // Append new grouped data to existing data
                        const lastGroup = this.bigSmallData[this.bigSmallData.length - 1];
                        if (lastGroup && lastGroup[0] === groupedData[0][0]) {
                            // Merge groups if the last group and the new group start with the same item
                            this.bigSmallData = [...this.bigSmallData.slice(0, -1), ...groupedData];
                        } else {
                            this.bigSmallData = [...this.bigSmallData, ...groupedData];
                        }
                    }
                    this.totalPages = response.data.total_pages; // Update totalPages dynamically
                })
                .catch(error => {
                    console.error('Error fetching history data:', error);
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },

        groupAdjacentItems(data) {
            if (data.length === 0) return [];

            let grouped = [];
            let currentGroup = [data[0]];

            for (let i = 1; i < data.length; i++) {
                if (data[i] === data[i - 1]) {
                    currentGroup.push(data[i]);
                } else {
                    grouped.push(currentGroup);
                    currentGroup = [data[i]];
                }
            }

            grouped.push(currentGroup);

            return grouped;
        },

        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false &&  scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchBigSmallData();
            }
        }
    }
}
</script>
