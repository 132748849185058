<template>
    <div class="groupBattle-wrapper">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="innerWrap">
            <div class="top-bannerWrap">
                <img class="main-bg" :src='GroupBattleTopBanner' alt="Opinion Bet Banner" />
                <div class="countdown-boxes">
                    <TopGameCountDown />
                </div>
            </div>
            <div class="rewards-bg">
                <h3 class="txtb">Total Rewards</h3>
                <img class="HD-bg" :src='TopGameTodayDateBG' alt="Date Bg" />
                <img class="heightAuto list-top-bg" :src='TopGameTodayListTopBG' alt="List Top Bg" />
                <div class="reward-boxWrap">
                    <div class="mainReward">
                        <div class="reward-box">
                            <div class="reward-HDBox">
                                <h3>Weekly Reward</h3>
                                <span>{{ groupBattleTotalreward }}
                                    <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" />
                                </span>
                            </div>
                            <div class="diamondIcon-box">
                                <img :src='DiamondsImage' alt="Diamonds Image" />
                            </div>
                        </div>
                    </div>
                    <div class="coin-box">
                        <div class="coin-text">
                            <img :src="RewardCoin1" class="coin-Icon" alt="R1">
                            <span v-if="groupBattleWeeklyrewards.length > 0">{{ groupBattleWeeklyrewards[0].reward_coin
                                }} <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" /></span>
                        </div>
                        <div class="coin-text">
                            <img :src="RewardCoin2" class="coin-Icon" alt="R2">
                            <span v-if="groupBattleWeeklyrewards.length > 1">{{ groupBattleWeeklyrewards[1].reward_coin
                                }}<img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" /></span>
                        </div>
                        <div class="coin-text">
                            <img :src="RewardCoin3" class="coin-Icon" alt="R3">
                            <span v-if="groupBattleWeeklyrewards.length > 2">{{ groupBattleWeeklyrewards[2].reward_coin
                                }}<img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" /></span>
                        </div>
                    </div>
                    <div class="rewardsRankBox-row">
                        <div class="rewardsRankBox-box rewardsRankBox-col-6">
                            <span v-if="groupBattleWeeklyrewards.length > 3">Rank 4th : {{
                                groupBattleWeeklyrewards[3].reward_coin }}
                                <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" />
                            </span>
                        </div>
                        <div class="rewardsRankBox-box rewardsRankBox-col-6">
                            <span v-if="groupBattleWeeklyrewards.length > 4">Rank 5th : {{
                                groupBattleWeeklyrewards[4].reward_coin }}
                                <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" />
                            </span>
                        </div>
                    </div>
                    <div class="rewardsRankBox-box">
                        <span v-if="groupBattleWeeklyrewards.length > 9">Rank 6th to 10th:&nbsp; {{
                            groupBattleWeeklyrewards[9].reward_coin }}
                            <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" />
                        </span>
                    </div>
                </div>
            </div>
            <div class="rewards-bg groupBattle-dataWrapper">
                <h3 class="txtb rangeDate-tabWrapper">
                    <span class="prevBtn" :class="{ disabled: currentIndex === groupBattleDateRangeData.length - 1 }"
                        @click="prevTab"><img :src="NextTabIcon" alt="Previous Icon"></span>
                    <div :class="['range-date', `range-date-${index}`, , { 'active': index === currentIndex }]"
                        v-for="(dateRange, index) in groupBattleDateRangeData" :key="dateRange.index">
                        {{ dateRange }}
                    </div>
                    <span class="nextBtn" :class="{ disabled: currentIndex === 0 }" @click="nextTab"><img
                            :src="NextTabIcon" alt="Next Icon"></span>
                </h3>
                <img class="HD-bg" :src='TopGameTodayDateBG' alt="Date Bg" />
                <img class="heightAuto list-top-bg" :src='TopGameTodayListTopBG' alt="List Top Bg" />
                <div>
                    <div class="listingPattern">
                        <div class="fixHeight">
                            <div class="listBoxes" v-for="(reward, index) in groupBattleWeeklydata" :key="reward.id"
                            @click="redirectProfile(reward.group_detail.user_id, reward.group_detail.profile_id)">
                                <div class="leftBxb">
                                    <!-- Rank Numbers and Group Image -->
                                    <div class="groupImageBox">
                                        <img :src="reward.group_detail && reward.group_detail.pic ? reward.group_detail.pic : defaultUserImage"
                                            class="groupImage" alt="Group Profile Image" />
                                    </div>
                                    <div class="groupName">
                                        <!-- Display group name if total_members is not null -->
                                        <div v-if="reward.group_detail && reward.group_detail.total_members !== null"
                                            class="groupName-In">
                                            <span class="groupName-txt">
                                                <div
                                                    :class="{ 'marqueeContainer': (reward.group_detail && reward.group_detail.name && reward.group_detail.name.length > 6) }">
                                                    <div v-if="reward.group_detail && reward.group_detail.name && reward.group_detail.name.length > 6"
                                                        class="marquee">
                                                        <span class="marquee-content">{{ reward.group_detail.name
                                                            }}</span>
                                                    </div>
                                                    <div v-else>
                                                        {{ reward.group_detail && reward.group_detail.name ?
                                                            reward.group_detail.name : 'NA' }}
                                                    </div>
                                                </div>
                                            </span>
                                            <!-- Total member count displayed only if total_members is not null -->
                                            <div
                                                v-if="reward.group_detail && reward.group_detail.total_member_count !== null">
                                                <b>({{ reward.group_detail.total_member_count }})</b>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <b>(0)</b>
                                        </div>

                                        <!-- Hide the user profile images section if total_members is null -->
                                        <div v-if="reward.group_detail && reward.group_detail.total_members !== null"
                                            class="userImage">
                                            <div v-for="(member, index) in reward.group_detail.total_members.slice(0, 4)"
                                                :key="member.id" :class="`userPfImage userPfImage-${index + 1}`">
                                                <img :src="member.user.profile_image ? member.user.profile_image : defaultUserImage"
                                                    :alt="member.user.name" class="groupImage" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Other content in the right section -->
                                <div class="rightBxb">
                                    <div class="rewards-valueBox" v-if="currentIndex === 1 || currentIndex === 2">
                                        {{ reward.reward_coin }}
                                        <img :src="DiamondIcon" class="diamondIcon" alt="Single Diamond Icon" />
                                    </div>
                                    <div class="reciveCoinBox">
                                        <div class="received-vale">
                                            <span>{{ reward.total_tips }}</span>
                                            <span class="received-coinIcon">
                                                <img :src='RewardCoin1' class="coin-Icon" alt="" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import TopGameCountDown from '../TopGames/TopGameCountDown.vue';
import LoadingOverlay from '../LoadingOverlay.vue'
import GroupBattleTopBanner from '../../assets/images/group-battle/groupBattle-topBanner.webp';
import TopGameTodayDateBG from '../../assets/images/TopGameToday-date-bg.png'
import TopGameTodayListTopBG from '../../assets/images/TopGameToday-list-top-bg.png'
import defaultUserImage from '../../assets/images/default_profile.jpeg';
import DiamondsImage from '../../assets/images/group-battle/diamonds-image.png'
import DiamondIcon from '../../assets/images/diamond.png';
import GB_rank1 from '../../assets/images/group-battle/GB_rankIcon1.png'
import GB_rank2 from '../../assets/images/group-battle/GB_rankIcon2.png'
import GB_rank3 from '../../assets/images/group-battle/GB_rankIcon3.png'
import NextTabIcon from '../../assets/images/group-battle/next-Icon.svg'
import RewardCoin1 from '../../assets/images/r1.png'
import RewardCoin2 from '../../assets/images/r2.png'
import RewardCoin3 from '../../assets/images/r3.png'

export default {
    name: 'GroupBattle',
    components: {
        TopGameCountDown,
        LoadingOverlay,
    },
    data() {
        return {
            currentIndex: 0,
            groupBattleWeeklydata: [],
            groupBattleWeeklyrewards: [],
            groupBattleDateRangeData: [
                'Date Range 1',
                'Date Range 2',
                'Date Range 3',
            ],
            groupBattleTotalreward: '',
            GroupBattleTopBanner: GroupBattleTopBanner,
            TopGameTodayDateBG: TopGameTodayDateBG,
            TopGameTodayListTopBG: TopGameTodayListTopBG,
            defaultUserImage: defaultUserImage,
            DiamondsImage: DiamondsImage,
            DiamondIcon: DiamondIcon,
            GB_rank1: GB_rank1,
            GB_rank2: GB_rank2,
            GB_rank3: GB_rank3,
            NextTabIcon: NextTabIcon,
            RewardCoin1: RewardCoin1,
            RewardCoin2: RewardCoin2,
            RewardCoin3: RewardCoin3,

            loading: false,
        }
    },
    mounted() {
        this.fetchGroupBattleData();
    },
    methods: {
        getTypeByCurrentIndex() {
            switch (this.currentIndex) {
                case 0:
                    return 'currentweek';
                case 1:
                    return 'lastweek';
                case 2:
                    return 'last2ndweek';
                default:
                    return 'currentweek';
            }
        },
        fetchGroupBattleData() {
            this.loading = true;
            const type = this.getTypeByCurrentIndex();

            axios.get(`api/gold-coin-group-weekly-rewards?user_id=${this.userId}&type=${type}`).then(response => {
                this.groupBattleWeeklydata = response.data.result.weeklydata;
                this.groupBattleWeeklyrewards = response.data.result.weeklyrewards;
                this.groupBattleDateRangeData = response.data.result.daterange_str_data;
                this.groupBattleTotalreward = response.data.result.totalreward;
                this.checkAllImagesExist();
            })
                .catch(error => {
                    console.error('Error fetching Group Battle Data:', error);
                })
                .finally(() => {
                    this.loading = false;
                })
        },

        prevTab() {
            // Move to the previous item if not at the start
            if (this.currentIndex < this.groupBattleDateRangeData.length - 1) {
                this.currentIndex++;
                this.fetchGroupBattleData();
                this.checkAllImagesExist();
            }
        },
        nextTab() {
            // Move to the next item if not at the end
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.fetchGroupBattleData();
                this.checkAllImagesExist();
            }
        },

        // checkAllImagesExist() {
        //     this.groupBattleWeeklydata.forEach(reward => {
        //         this.checkImageExists(reward.group_detail.pic).then(exists => {
        //             reward.group_detail.pic = exists ? reward.group_detail.pic : this.defaultUserImage;
        //         });
        //         reward.group_detail.total_members.forEach(member => {
        //             this.checkImageExists(member.user.profile_image).then(exists => {
        //                 member.user.profile_image = exists ? member.user.profile_image : this.defaultUserImage;
        //             });
        //         });
        //     });
        // },

        checkAllImagesExist() {
            this.groupBattleWeeklydata.forEach(reward => {
                if (reward.group_detail && reward.group_detail.pic) {
                    this.checkImageExists(reward.group_detail.pic).then(exists => {
                        reward.group_detail.pic = exists ? reward.group_detail.pic : this.defaultUserImage;
                    });
                } else {
                    if (reward.group_detail) {
                        reward.group_detail.pic = this.defaultUserImage;
                    }
                }

                // Check each member's profile image if group_detail is valid
                if (reward.group_detail && reward.group_detail.total_members) {
                    reward.group_detail.total_members.forEach(member => {
                        if (member.user && member.user.profile_image) {
                            this.checkImageExists(member.user.profile_image).then(exists => {
                                member.user.profile_image = exists ? member.user.profile_image : this.defaultUserImage;
                            });
                        } else {
                            member.user.profile_image = this.defaultUserImage;
                        }
                    });
                }
            });
        },

        checkImageExists(imageUrl) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    resolve(true);
                };
                img.onerror = function () {
                    resolve(false);
                };
                img.src = imageUrl;
            });
        },

        redirectProfile(id, profile_id, level) {
            // if (window.navigator.userAgent.indexOf("Android") > -1) {
                // const baseURL = 'female-weekly-rewards';
                const baseURL = '#';
                const params = {
                    id: id,
                    profile_id: profile_id
                };

                const queryString = Object.keys(params)
                    .map(key => `${key}=${params[key]}`)
                    .join('&');
                const url = `${baseURL}?${queryString}`;
                  window.location.href = url;
                // Android.redirectProfile(url);
            // }
        }
    }
}
</script>