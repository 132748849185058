<template>
    <div class="historyTable-wrapper">
        <LoadingOverlay :loading="loading" class="popupLoading" />
        <div class="table-responsive" ref="tableContainer">
            <table class="table">
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>Result</th>
                        <th style="width: 96.17px;">Winners</th>
                        <th>
                            Prizes<span class="diamond-iconBox"><img :src='diamondIcon' alt="" /></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="bet in historyBets" :key="bet.contestid">
                        <td>{{ bet.contestid_hm }}</td>
                        <td>{{ bet.winning_no }}</td>
                        <td>{{ bet.total_winner }}</td>
                        <td>
                            <router-link :to="generateHistoryDetailsLink(bet.contestid)" class="go-histortDetails">
                                {{ bet.total_won }} <span class="angle"></span>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="scrollLoading" class="scrollLoading">Loading...</div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { RouterLink } from 'vue-router';
import LoadingOverlay from '../LoadingOverlay.vue'
import diamondIcon from '../../assets/images/diamond.png'
export default {
    name: 'History',
    components: {
        LoadingOverlay,
        RouterLink
    },
    data() {
        return {
            historyBets: [],
            diamondIcon: diamondIcon,
            currentPage: 1,
            loading: false,
            scrollLoading: false,
            totalPages: 1
        }
    },
    mounted() {
        this.userId = this.$route.params.user_id;
        this.fetchBetHistory();
        this.setupScrollListener();
    },
    methods: {
        fetchBetHistory() {
            if (this.loading || this.scrollLoading) return;
            this.scrollLoading = this.currentPage > 1;
            this.loading = this.currentPage === 1;
            const apiUrl = `api/betapi/bethistoryapi.php?page=${this.currentPage}`;
            axios.get(apiUrl)
                .then(response => {
                    this.historyBets = [...this.historyBets, ...response.data.data];
                    this.totalPages = response.data.total_pages;
                })
                .catch(error => {
                    console.error('Error fetching history data:', error);                    
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollLoading = false;
                })
        },
        generateHistoryDetailsLink(contestId) {
            const userId = this.userId;
            return {
                path: '/luckynumber/history-details/' +
                    `contest_id=${contestId}/user_id=${userId}`
            };
        },
        setupScrollListener() {
            const container = this.$refs.tableContainer;
            if (container) {
                container.addEventListener('scroll', this.handleScroll);
            }
        },
        handleScroll() {
            const container = this.$refs.tableContainer;
            if (!container) return;

            const scrollPosition = container.scrollTop + container.clientHeight;
            const pageHeight = container.scrollHeight;

            if ((this.loading || this.scrollLoading) == false && scrollPosition >= pageHeight - 100 && this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchBetHistory();
            }
        }
    }
}
</script>