<template>
    <div class="carSpeedRankContainer">
        <div class="backgroundWrapper" v-if="cargamedata && cargamedata.graph_cars_image && cargamedata.graph_image">
            <div class="top-headerBox">
                <div class="top-headerBoxIn">
                    <div class="backToApp" id="close" @click="viewClose('someStatus')">
                        <div class="angle white-angle"></div>
                    </div>
                    <div class="top-header-HD">
                        <h2 class="white-txt">{{ currentCar }}</h2>
                    </div>
                </div>
            </div>
            <div class="speedRank-listWrapper">
                <div class="podiumCarWrapper">
                    <div class="carImage">
                        <img :src="carImageUrl" :alt="currentCar" />
                    </div>
                    <div class="VictoryOddsBoxWrap">
                        <div class="VictoryBox">Victory : {{ carWinPercentage }}</div>
                        <div class="OddsBox">Odds : {{ carOddsVal }}</div>
                    </div>
                    <button type="button" class="customButton previousButton" :disabled="isFirstCar" :class="{ disabled: isFirstCar }"
                        @click="previousCar">
                        <span class="arrowButton"></span>
                    </button>
                    <button type="button" class="customButton nextButton" :disabled="isLastCar" :class="{ disabled: isLastCar }"
                        @click="nextCar"><span class="arrowButton"></span></button>
                </div>
                <div class="graphImage">
                    <img :src="graphImageUrl" :alt="'Graph ' + currentCar" />
                </div>
            </div>
        </div>
        <div v-else>
            <LoadingOverlay :loading="loading" />
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import LoadingOverlay from '../LoadingOverlay.vue'
export default {
    name: 'CarGameRoadGraph',    
    components: {
        LoadingOverlay,
    },
    data() {
        return {
            cargamedata: null,
            cars: [
                'Supercar', 'Sports Car', 'Car', 'SUV', 'ORV',
                'Stock Car', 'Monster Truck', 'Motorcycle', 'ATV'
            ],
            currentIndex: 5,
            loading: false,
        }
    },
    computed: {
        currentCar() {
            return this.cars[this.currentIndex];
        },
        carImageUrl() {
            if (this.cargamedata && this.cargamedata.graph_cars_image) {
                return require(`../../assets/images/car-game/graphImages/${this.cargamedata.graph_cars_image[this.currentCar]}`);
            }
            return '';
        },
        carOddsVal() {
            if (this.cargamedata && this.cargamedata.standard_odds) {
                return this.cargamedata.standard_odds[this.currentCar] || 'Odds not available';
            }
            return 'Odds not available';
        },
        carWinPercentage() {
            if (this.cargamedata && this.cargamedata.car_win_per) {
                const winValue = this.cargamedata.car_win_per[this.currentCar];
                if (winValue !== undefined) {
                    const winPercentage = parseFloat(winValue);
                    if (!isNaN(winPercentage)) {
                        return `${winPercentage.toFixed(2)}%`
                    }
                }
                return 'Win percentage data not available'
            }
            return 'Win percentage data not available';
        },
        graphImageUrl() {
            if (this.cargamedata && this.cargamedata.graph_image) {
                return require(`../../assets/images/car-game/graphImages/${this.cargamedata.graph_image[this.currentCar]}`);
            }
            return '';
        },
        isFirstCar() {
            return this.currentIndex === 0;
        },
        isLastCar() {
            return this.currentIndex === this.cars.length - 1;
        },
    },
    mounted() {
        this.fetchcargamedata();
        this.setCarFromUrl();
    },
    methods: {
        fetchcargamedata() {
            this.loading = true;
            const apiUrl = `api/cargame/car_data_api.php`;
            axios.get(apiUrl)
                .then(response => {
                    this.cargamedata = response.data;
                })
                .catch(error => {
                    console.error("Error Fetching car graph data", error);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        setCarFromUrl() {
            const carName = this.$route.query.car; // Assuming the car name is passed as a query parameter
            if (carName) {
                const index = this.cars.indexOf(carName);
                if (index !== -1) {
                    this.currentIndex = index;
                }
            }
        },
        previousCar() {
            if (this.currentIndex > 0) {
                this.currentIndex = (this.currentIndex - 1 + this.cars.length) % this.cars.length
            }
        },
        nextCar() {
            if (this.currentIndex < this.cars.length - 1) {
                this.currentIndex = (this.currentIndex + 1) % this.cars.length
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);
            }
        },
    }
}
</script>