<template>
    <div>
        <div class="position-relative historyTab-wrapper spaceCraftGame">
            <div class="sc-backToApp backToApp" id="close" @click="viewClose('someStatus')">
                <div class="angle white-angle"></div>
            </div>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'history' }" @click="activateTab('history')"
                        data-toggle="tab" href="#history" role="tab">History</div>
                </li>
                <li class="nav-item">
                    <div class="nav-link" :class="{ 'active': activeTab === 'myhistory' }"
                        @click="activateTab('myhistory')" data-toggle="tab" href="#myhistory" role="tab">My History
                    </div>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" :class="{ 'active': activeTab === 'history' }" id="history" role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedHistory">
                    <SpaceCraftGameHistory />
                </div>
            </div>
            <div class="tab-pane" :class="{ 'active': activeTab === 'myhistory' }" id="myhistory" role="tabpanel">
                <div class="tab-contentIn" v-if="isLoadedMyHistory">
                    <SpaceCraftGameMyHistory />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SpaceCraftGameHistory from './SpaceCraftGameHistory.vue'
import SpaceCraftGameMyHistory from './SpaceCraftGameMyHistory.vue'
export default {
    name: 'HistoryTabComponent',
    components: {
        SpaceCraftGameHistory,
        SpaceCraftGameMyHistory
    },
    data(){
        return {
            activeTab: 'history',
            isLoadedHistory: false,
            isLoadedMyHistory: false,
        }
    },
    methods: {
        activateTab(tabId) {
            this.activeTab = tabId;
            if (tabId === 'history' && !this.isLoadedHistory) {
                this.isLoadedHistory = true;
            }
            if (tabId === 'myhistory' && !this.isLoadedMyHistory) {
                this.isLoadedMyHistory = true;
            }
        },
        //Close Webview on back arrow click
        viewClose(status) {
            if (window.navigator.userAgent.indexOf("Android") > -1) {
                Android.viewClose(status);        
            }
        }
    },
    mounted() {
        this.isLoadedHistory = true;
    },
}
</script>